import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-body-2"
}
const _hoisted_2 = { class: "vz-calendar__header d-flex justify-space-between align-center ma-2" }
const _hoisted_3 = { class: "d-flex mx-2 gap-2 text-title-1" }
const _hoisted_4 = { class: "vz-calendar__weekdays text-body-2" }
const _hoisted_5 = { class: "vz-calendar__container" }
const _hoisted_6 = { key: 1 }

import type { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';
import type { CalendarDay } from '@/shared/services/calendar-service/types/calendar-day';
import { computed, type PropType, ref, watch } from 'vue';
import Calendar from '@/shared/services/calendar-service/calendar.service';
import VzCalendarDay from '@/shared/components/calendar/components/vz-calendar-day.vue';
import { useTranslator } from '@/plugins/i18n/helpers';
import dayjs from 'dayjs';
import { useValidator } from '@shared/components/fields/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-calendar',
  props: {
  name: { type: String as PropType<string | undefined>, default: undefined },
  viewDate: { type: Object as PropType<Date | undefined | null>, default: undefined },
  modelValue: { type: Object as PropType<Date | undefined | null>, required: true },
  label: { type: String, default: '' },
  minDate: { type: Number, default: undefined }, // timestamp
  maxDate: { type: Number, default: undefined }, // timestamp
  events: { type: Array as PropType<Array<Record<string, any>>>, default: () => [] }, // events
  idKey: { type: String, default: '_id' }, // event id key name
  titleKey: { type: String, default: 'title' }, // event title key name
  dateFromKey: { type: String, default: 'dateFrom' }, // event start date key name
  dateToKey: { type: String, default: 'dateTo' }, // event end date key name
  colors: { type: Object as PropType<{ key: string; map: Record<string, string> } | undefined>, default: undefined },
  loading: { type: Boolean, default: false },
  extendable: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  hideDetails: { type: Boolean, default: false },
  rules: { type: Object as PropType<ValidatorFieldRules>, default: () => ({}) },
  errorMessage: { type: String as PropType<string | null>, default: null },
  dayBorder: { type: Boolean, default: true },
  dotMark: { type: Boolean, default: false },
},
  emits: ['update:events', 'update:view', 'update:model-value'],
  setup(__props, { emit: __emit }) {

const t = useTranslator();

const props = __props;

const emit = __emit;
const localDate = ref<number>((props.modelValue ? new Date(props.modelValue) : new Date()).valueOf());

const calendar = new Calendar({
  idKey: props.idKey,
  titleKey: props.titleKey,
  fromKey: props.dateFromKey,
  toKey: props.dateToKey,
  colors: props.colors,
});

const vModel = computed({
  get: (): Date => {
    if (props.modelValue) {
      return new Date(props.modelValue);
    } else if (props.minDate) {
      return new Date(props.minDate);
    }

    return new Date();
  },
  set: (value) => {
    localDate.value = value.valueOf();

    emit('update:model-value', value.valueOf());
  },
});

const today = computed(() => new Date(vModel.value.valueOf()).setHours(0, 0, 0, 0).valueOf());

const monthDisplay = computed(() => dayjs().month(new Date(localDate.value).getMonth()).format('MMMM'));
const yearDisplay = computed(() => dayjs().year(new Date(localDate.value).getFullYear()).format('YYYY'));
const currentValue = computed(() => localDate.value.valueOf());
const currentYear = computed(() => new Date(localDate.value).getFullYear());
const currentMonth = computed(() => new Date(localDate.value).getMonth());
const matrixDays = computed((): Array<CalendarDay | null> => calendar.days(currentYear.value, currentMonth.value));
const eventsMap = computed(() => calendar.events(props.events));
const dayEvents = computed(() => eventsMap.value[currentValue.value]);

const onSelect = (date: CalendarDay | null) => {
  if (!date) {
    return;
  }

  vModel.value = date.date;
};

const onNext = () => {
  const date = new Date(localDate.value);
  const month = date.getMonth();
  const year = date.getFullYear();

  if (month === 11) {
    date.setFullYear(year + 1);
    date.setMonth(0);
  } else {
    date.setMonth(month + 1);
  }

  localDate.value = date.valueOf();
  emit('update:view', date);
};

const onBack = () => {
  const date = new Date(localDate.value);
  const month = date.getMonth();
  const year = date.getFullYear();

  if (month === 0) {
    date.setFullYear(year - 1);
    date.setMonth(11);
  } else {
    date.setMonth(month - 1);
  }

  localDate.value = date.valueOf();
  emit('update:view', date);
};

watch(
  () => [dayEvents.value],
  () => emit('update:events', dayEvents.value),
  { immediate: true }
);

const { validateMessage, isTouched } = useValidator(
  computed(() => vModel.value.valueOf()),
  computed(() => props.rules),
  props.name || props.label
);

return (_ctx: any,_cache: any) => {
  const _component_vz_icon = _resolveComponent("vz-icon")!
  const _component_vz_badge = _resolveComponent("vz-badge")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["vz-calendar", { 'vz-calendar--loading': __props.loading, 'vz-calendar--disabled': __props.disabled }])
  }, [
    (__props.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.$t(__props.label)), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_vz_icon, {
        clickable: "",
        name: "svg:previous",
        size: "1.25rem",
        "aria-label": _unref(t)('COMPONENT_LABELS.BUTTON', { value: 'CALENDAR.PREVIOUS_MONTH' }),
        onClick: onBack
      }, null, 8, ["aria-label"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(monthDisplay.value), 1),
        _createElementVNode("span", null, _toDisplayString(yearDisplay.value), 1)
      ]),
      _createVNode(_component_vz_icon, {
        clickable: "",
        role: "button",
        name: "svg:arrow-right",
        size: "1.25rem",
        "aria-label": _unref(t)('COMPONENT_LABELS.BUTTON', { value: 'CALENDAR.NEXT_MONTH' }),
        onClick: onNext
      }, null, 8, ["aria-label"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(calendar).weekdays, (calendarDay, index) => {
        return (_openBlock(), _createElementBlock("div", { key: calendarDay }, _toDisplayString(_unref(dayjs)().day(index).format('ddd')), 1))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(matrixDays.value, (date, index) => {
        return (_openBlock(), _createBlock(VzCalendarDay, {
          key: index,
          class: _normalizeClass({
          'vz-calendar__day': !!date,
          'vz-calendar__day--outline': __props.dayBorder,
          'vz-calendar__day--today': date?.date === new Date(),
          'vz-calendar__day--active': date && today.value === date?.date.valueOf(),
          'vz-calendar__day--mark': date?.date && !!eventsMap.value[date?.date.valueOf()]?.length,
          'vz-calendar__day--disabled':
            __props.disabled || (date?.date && __props.minDate && date.date.valueOf() < __props.minDate) || (date?.date && __props.maxDate && date.date.valueOf() > __props.maxDate),
        }),
          role: "button",
          "aria-label": _unref(t)('COMPONENT_LABELS.BUTTON', { value: 'CALENDAR.SELECT_DAY' }),
          extendable: __props.extendable,
          day: date?.day || 0,
          onClick: ($event: any) => (onSelect(date))
        }, {
          default: _withCtx(() => [
            date
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (__props.dotMark && eventsMap.value[date?.date.valueOf() || 0]?.length)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(eventsMap.value[date?.date.valueOf() || 0], (event, eventIndex) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: eventIndex,
                          class: "vz-calendar__day-mark",
                          style: _normalizeStyle({ color: event.color, width: event.percentOfDay + '%', marginInlineStart: event.percentFromStartDate + '%' })
                        }, _toDisplayString(__props.dotMark ? '-' : event.title), 5))
                      }), 128))
                    : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(eventsMap.value[date?.date.valueOf() || 0], (event, eventIndex) => {
                        return (_openBlock(), _createBlock(_component_vz_badge, {
                          key: eventIndex,
                          class: "max-line-1 font-size-14",
                          prefix: event.time,
                          text: event.title,
                          color: event.color
                        }, null, 8, ["prefix", "text", "color"]))
                      }), 128))
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["class", "aria-label", "extendable", "day", "onClick"]))
      }), 128))
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(['vz-calendar__error', { 'vz-calendar__error--hidden': __props.hideDetails }])
    }, [
      (_unref(validateMessage))
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: _normalizeClass({ 'vz-datepicker__error-internal': !_unref(isTouched) })
          }, _toDisplayString(_ctx.$t(_unref(validateMessage))), 3))
        : (__props.errorMessage)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t(__props.errorMessage)), 1))
          : _createCommentVNode("", true)
    ], 2)
  ], 2))
}
}

})