/**
 * Generates a hash based on the provided value and key.
 * @param value The base value for generating the key.
 * @param key An optional additional key component.
 * @returns A string representing the generated hash.
 */
const generateKey = (value: string | number = Date.now(), key?: string | number): string => {
  const input = `${value}${key || ''}`;

  // Compute hash by reducing ASCII values with powers of the index
  const hash = input.split('').reduce((sum, char, index) => {
    const charCode = char.charCodeAt(0);

    return sum + Math.pow(charCode, (index % 10) + 1);
  }, 0);

  // Convert to hexadecimal string
  return hash.toString(16);
};

/**
 * Generates a unique key based on the current timestamp and optional additional key.
 * @param value The base value for generating the key. Defaults to the current timestamp.
 * @param key An optional additional key component.
 * @returns A unique key string.
 */
export const uniqueKey = (value: string | number = Date.now(), key?: string | number): string => {
  return generateKey(value, key);
};
